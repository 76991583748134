import React from "react";
import Raven from "raven-js";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Header from "../header";
import Footer from "../footer";

import "../../styles/index.scss";

if (process.env.NODE_ENV === "production" && typeof window !== "undefined") {
	Raven.config("https://cdabd54e77f84f35851ad3f9b0e9602d@sentry.io/1216381", {
		environment: "production",
	}).install();

	window.addEventListener("unhandledrejection", (event) => {
		Raven.captureException(event.reason);
	});
}

const Layout = ({ children, pageMeta = {} }) => (
	<React.Fragment>
		<Helmet
			title={pageMeta.title}
			meta={[
				{
					name: "description",
					content: pageMeta.description,
				},
				{
					name: "keywords",
					content:
						pageMeta.keywords || "transactional emails, email service provider",
				},
			]}>
			<html lang="en" />
		</Helmet>

		<Header />
		<div>{children}</div>
		<Footer />
	</React.Fragment>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;

export const twoColumnsImage = graphql`
	fragment twoColumnsImage on File {
		childImageSharp {
			fluid(maxWidth: 1600, quality: 100) {
				...GatsbyImageSharpFluid
			}
		}
	}
`;